<template>
  <div v-if="options && options.length" class="selector" v-loading="loading">
    <div class="selector__content" @click="showOptions = !showOptions">
      <div class="selector__title" :class="{ 'selector__title--disabled': !selectedValue }">
        {{ title }}
      </div>
      <div class="selector__value">
        {{ selectedValue }}
      </div>
      <div class="selector__arrow" :class="{ 'selector__arrow--open': showOptions }">
        <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
      </div>
    </div>
    <div v-if="showOptions" class="selector__options">
      <div v-for="option in options" :key="option.id" class="selector__options-item"
        @click="chooseOption(option.name, option.id)">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformSelector",
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selectFitrstItem: {
      type: Boolean,
      default: true
    },

  },
  data() {
    return {
      loading: false,
      selectedValue: this.selectFitrstItem ? this.options[0].name : null,
      showOptions: false,
    }
  },
  methods: {
    chooseOption(name, id) {
      this.selectedValue = name
      this.showOptions = false
      this.$emit('handleChange', id)
    },
    serActiveName(name) {
      this.selectedValue = name
    },
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.selector')) {
        this.showOptions = false
      }
    }, true)
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixin";

.selector {
  position: relative;

  &__content {
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    height: 5rem;
    position: relative;
    padding: 0.7rem 1.5rem;
    width: 22rem;
  }

  &__title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $textGrey;
    margin-bottom: 0.2rem;
    transition: .2s;

    &--disabled {
      position: relative;
      top: 11px;
    }
  }

  &__value {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;

    img {
      transition: 0.3s ease-out;
    }

    &--open {
      img {
        transition: 0.3s ease-out;
      }

      &--open {
        img {
          transform: rotate(180deg);
        }
      }
    }

    // &__options {
    //   position: absolute;
    //   top: calc(100% + 1rem);
    //   left: 0;
    //   width: 100%;
    //   background: $backgroundInput;
    //   border: 1px solid $border;
    //   border-radius: 2px;
    //   z-index: 1;

    //   &-item {
    //     cursor: pointer;
    //     transition: 0.3s ease-out;
    //     padding: 0.7rem 1.5rem;

    //     &:hover {
    //       background: var(--primary);
    //       color: $basicWhite;
    //     }
    //   }
    // }

    @include below(769px) {

      &__content {
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    z-index: 999;

    &-item {
      cursor: pointer;
      transition: 0.3s ease-out;
      padding: 0.5rem 1.5rem;

      &:hover {
        background: var(--primary);
        color: $basicWhite;
      }
    }

    &-search {
      &.form-input {
        width: calc(100% - 20px);
        margin: 10px;
      }
    }
  }

  @include below(769px) {

    &__content {
      width: 100%;
    }
    &__options {
      width: 100%;
    }
  }
}
</style>
